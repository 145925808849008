<template>
  <div class="ai-assistant-panel flex-column">
    <button class="close-button btn btn-icon btn-sm btn-primary hidden-xs hidden-sm" @click="toggleOpen">
      <icon icon="fluent:dismiss-16-filled" />
    </button>
    <div class="drag-handle mb-md" @click="toggleOpen">
      <div class="drag-handle-bar"></div>
    </div>
    <div class="content-container flex-1 min-h-0" :class="{ open }">
      <div class="content flex-column">
        <div
          v-if="tab === 'chat'"
          class="header-chat flex-row align-items-center justify-content-space-around bg-surface-emphasis-primary-1 rounded p-md relative"
        >
          <button class="btn btn-sm btn-tertiary flex-row align-items-center gap-sm" @click="newChat">
            <icon icon="fluent:wand-16-filled" />
            {{ t("ai_assistant.new_chat") }}
          </button>
          <div class="fg-border-variant">|</div>
          <button class="btn btn-sm btn-tertiary flex-row align-items-center gap-sm" @click="openHistory">
            <icon icon="fluent:history-16-filled" />
            {{ t("ai_assistant.history") }}
          </button>
        </div>
        <div
          v-if="tab === 'history'"
          class="header-history flex-row align-items-center bg-surface-emphasis-primary-1 rounded p-md relative"
        >
          <button class="btn btn-sm btn-tertiary flex-row align-items-center gap-sm" @click="openChat">
            <icon icon="fluent:arrow-left-16-filled" />
            {{ t("actions.back") }}
          </button>
        </div>
        <component :is="activeComponent" @create-message="onCreateMessage" @select-session="onSelectSession" />
      </div>
    </div>
    <PromptField
      :value="messageInputValue"
      :disabled="isProcessing"
      @input="messageInputValue = $event"
      @focus="onInputFocus"
      @submit="onSubmit"
    />
  </div>
</template>

<script setup lang="ts">
import { useCreateAiMessage, useCreateAiSession } from "@/ai-assistant/ai-assistant-api";
import AiMessage from "@/ai-assistant/ai-message";
import AiSession from "@/ai-assistant/ai-session";
import { computed, ref } from "vue";
import { Icon } from "@iconify/vue2";
import { useStore } from "@/composables/store";
import Mutations from "@/store/mutations";
import { useRoute, useRouter } from "vue-router/composables";
import { useI18n } from "@/composables/i18n";
import { useIsMobile } from "@/composables/is-mobile";
import { filterToFlyover } from "@/lib/filter-util";
import _ from "lodash";
import AiAssistantChat from "@/ai-assistant/ai-assistant-chat.vue";
import AiAssistantHistory from "@/ai-assistant/ai-assistant-history.vue";
import { useQueryClient } from "@tanstack/vue-query";
import PromptField from "@/ai-assistant/prompt-field.vue";

const tab = ref("chat");

const activeComponent = computed(() => {
  switch (tab.value) {
    case "chat":
      return AiAssistantChat;
    case "history":
      return AiAssistantHistory;
  }
});

const messageInputValue = ref("");

const route = useRoute();

const router = useRouter();

const store = useStore();

const { t } = useI18n();

const createSessionMutation = useCreateAiSession();

const createMessageMutation = useCreateAiMessage();

const isMobile = useIsMobile();

const queryClient = useQueryClient();

const messageContext = computed(() => {
  const context = {};
  const sessionId = store.state.aiAssistant.session_id;
  const messages = queryClient
    .getQueryData<AiMessage[]>(["aiMessages", sessionId])
    ?.filter((m) => m.sender === "ai");
  const lastMessage = _.last(messages) as AiMessage;
  const lastComponent = lastMessage ? store.state.aiAssistant.components[lastMessage.id] : null;
  if (lastComponent?.filterConfiguration) {
    context["filterConfiguration"] = filterToFlyover(lastComponent.filterConfiguration);
  }
  if (lastMessage?.data?.chart) {
    context["chart"] = lastMessage.data.chart;
  }

  return context;
});

const closeIfMobile = () => {
  if (isMobile.value) {
    setTimeout(() => {
      if (open) {
        store.commit(Mutations.closeAiAssistant);
      }
    }, 2000);
  }
};

const onCreateMessage = ({ message, use = null }) => {
  createMessage(message, use);
};

const createMessage = (message: string, use = null) => {
  messageInputValue.value = "";

  if (route.name !== "zed_workspace") {
    router.push({ name: "zed_workspace" });
  }

  const context = messageContext.value;

  const _doCreateSession = () => {
    createMessageMutation.mutate(
      { session_id: store.state.aiAssistant.session_id, data: { message, context, use } },
      {
        onSuccess: () => {
          closeIfMobile();
        },
      }
    );
  };

  if (!store.state.aiAssistant.session_id) {
    createSessionMutation.mutate(null, {
      onSuccess: (data) => {
        store.commit(Mutations.setAiSessionId, data.session_id);
        _doCreateSession();
      },
    });
  } else {
    _doCreateSession();
  }
};

const isProcessing = computed(() => createSessionMutation.isPending.value || createMessageMutation.isPending.value);

const open = computed(() => store.state.aiAssistant.open);

const onSubmit = async () => {
  createMessage(messageInputValue.value);
};

const onInputFocus = () => {
  if (!open.value) {
    toggleOpen();
  }
};

const toggleOpen = () => {
  store.commit(Mutations.toggleAiAssistant);
};

const newChat = () => {
  store.commit(Mutations.setAiSessionId, null);
  openChat();
};

const openChat = () => {
  tab.value = "chat";
};

const openHistory = () => {
  tab.value = "history";
};

const onSelectSession = (session: AiSession) => {
  store.commit(Mutations.setAiSessionId, session.session_id);
  tab.value = "chat";
  if (route.name !== "zed_workspace") {
    router.push({ name: "zed_workspace" });
  }
  closeIfMobile();
};
</script>

<style lang="scss" scoped>
.ai-assistant-panel {
  // background-color: var(--color-surface-neutral-1);
  background-color: white;
  width: 100%;
  height: auto;
  max-height: 70vh;
  padding: 5px 15px 15px 15px;
  border-top-left-radius: var(--border-radius-md);
  border-top-right-radius: var(--border-radius-md);
  box-shadow: 0px -2px 20px 0px rgba(217, 217, 217, 0.5);
  font-size: 12px;

  .drag-handle {
    display: flex;
    justify-content: center;
    padding: 5px;

    .drag-handle-bar {
      width: 40px;
      height: 5px;
      border-radius: 5px;
      background-color: var(--color-border-variant-2);
    }

    @media (min-width: 992px) {
      display: none;
    }
  }

  .content-container {
    overflow: hidden;
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.25s ease;

    &.open {
      grid-template-rows: 1fr;
    }

    .content {
      overflow: hidden;
    }

    @media screen and (min-width: 992px) {
      display: flex;
      flex-direction: column;

      overflow: visible;

      .content {
        flex: 1;
        min-height: 0;
        overflow: visible;
        padding-bottom: 15px;
      }
    }
  }

  @media (min-width: 768px) {
    .header-chat,
    .header-history {
      background-image: url("~@assets/images/zed-full.png");
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: right;
    }

    .header-chat {
      justify-content: flex-start;
    }
  }

  button.close-button {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(50%) translateX(-30%);
    font-size: 16px;
  }

  @media (min-width: 768px) {
    max-height: 50vh;
  }

  @media (min-width: 992px) {
    height: 100%;
    max-height: unset;
    padding: 15px;
    border-bottom-left-radius: var(--border-radius-md);
    border-bottom-right-radius: var(--border-radius-md);
  }
}
</style>
