<template>
  <div class="time-series-section">
    <div class="option">
      <label>{{ $t("filter.config.metrics") }}</label>
      <select :value="section.metric" @input="updateMetric">
        <option v-for="option in metricsOptions" :value="option.id">{{ option.name }}</option>
      </select>
    </div>
    <div class="option">
      <label></label>
      <div class="checkbox">
        <label>
          <input
            type="checkbox"
            :checked="section.cumulative"
            :disabled="!!section.top_grouping"
            @input="updateCumulative"
          />{{ $t("cumulative") }}
        </label>
      </div>
    </div>
    <div class="option">
      <label>{{ $t("newsletter.definition.sections.common.time_period") }}</label>
      <timePeriodDropdown
        :show-title="false"
        :custom="false"
        :available-items="timePeriods"
        :available-series-types="seriesTypes"
        :value="selection"
        @update="updateSelection"
      ></timePeriodDropdown>
    </div>
    <div class="option" v-if="openingHoursDefined">
      <label>{{ $t("filter.config.limit_to_business_hours") }}</label>
      <businessHoursSelector
        :title="false"
        :value="section.limit_to_business_hours"
        @update="updateLimitToBusinessHours"
      ></businessHoursSelector>
    </div>
    <div class="option">
      <label>{{ $t("newsletter.definition.sections.common.top_grouping") }}</label>
      <select :value="section.top_grouping" @input="updateTopGrouping">
        <option v-for="option in groupingOptions" :value="option.id">{{ option.name }}</option>
      </select>
    </div>
    <div class="option" v-if="section.top_grouping">
      <label>{{ $t("filter.config.sort") }}</label>
      <select :value="section.sort" @input="updateSort">
        <option v-for="option in sortOptions" :value="option.id">{{ option.name }}</option>
      </select>
    </div>
    <div class="option" v-if="section.top_grouping">
      <label>{{ $t("newsletter.definition.sections.common.entries_to_show") }}</label>
      <select :value="section.limit" @input="updateLimit">
        <option v-for="option in limitOptions" :value="option.id">{{ option.name }}</option>
      </select>
    </div>
    <div class="option">
      <label>{{ $t("newsletter.definition.sections.common.chart_type.description") }}</label>
      <select :value="section.displayType" @input="updateDisplayType">
        <option v-for="option in displayTypes" :value="option.id">{{ option.name }}</option>
      </select>
    </div>
    <div class="option">
      <label></label>
      <div class="checkbox">
        <label>
          <input type="checkbox" :checked="section.showDatalabels" @input="updateShowDatalabels" />{{
            $t("newsletter.definition.sections.common.chart_type.show_datalabels")
          }}
        </label>
      </div>
    </div>
    <div class="option">
      <label>{{ $t("newsletter.definition.sections.common.comparison") }}</label>
      <comparisonSelector
        :value="section.comparison"
        :choices="comparisons"
        @input="updateComparison"
      ></comparisonSelector>
    </div>
    <div class="option" v-if="lflEnabled && section.comparison && isTimeComparison(section.comparison.type)">
      <label>{{ $t("filter.config.like_for_like") }}</label>
      <likeForLikeSelector
        :config="section.like_for_like"
        :title="null"
        @update="updateLikeForLike"
      ></likeForLikeSelector>
    </div>
    <div
      class="option"
      v-if="section.comparison && ['prev_year_corresponding', 'budget'].indexOf(section.comparison.type) >= 0"
    >
      <label></label>
      <div class="checkbox">
        <label>
          <input type="checkbox" :checked="section.benchmarkForward" @input="updateBenchmarkForward" />{{
            $t("newsletter.definition.sections.common.comparison_forward")
          }}
        </label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { defineComponent, PropType } from "vue";
import MenuItem from "../../interfaces/menu-item";
import { timeComparisonTypes } from "../../filters/time-period";
import timePeriodDropdown from "../../components/time-period-dropdown.vue";
import businessHoursSelector from "../../components/business-hours-selector.vue";
import comparisonPillList from "../../components/comparison-pill-list.vue";
import likeForLikeSelector from "../../components/like-for-like-selector.vue";
import newsletterSectionInsights from "../newsletter-section-insights.vue";
import newsletterSectionAlertConditions from "../newsletter-section-alert-conditions.vue";
import comparisonSelector from "../../components/comparison-selector.vue";
import _ from "lodash";
import i18n from "../../i18n";
import { displayTypes, sortOptions } from "../constants";

export default defineComponent({
  components: {
    timePeriodDropdown,
    businessHoursSelector,
    comparisonPillList,
    likeForLikeSelector,
    newsletterSectionInsights,
    newsletterSectionAlertConditions,
    comparisonSelector,
  },
  computed: {
    selection() {
      return (
        this.section.time_period && {
          type: this.section.time_period,
          series: this.section.time_series_type,
        }
      );
    },
    metricsOptions(): MenuItem[] {
      return this.$store.state.parameters.metrics.all;
    },
    groupingOptions(): MenuItem[] {
      const options = this.$store.state.parameters.grouping.all;
      return options && [{ id: "", name: i18n.t("newsletter.definition.sections.common.no_grouping") }, ...options];
    },
    comparisons() {
      const comparisons = this.$store.state.parameters.comparisons.all;
      return (
        comparisons && [{ type: "no_comparison", name: i18n.t("filters.benchmark.no_comparison") }, ...comparisons]
      );
    },
    limitOptions() {
      return [1, 2, 3, 4, 5, 10].map(function(v) {
        return { id: v, name: v };
      });
    },
    sortOptions() {
      return sortOptions;
    },
    displayTypes() {
      return displayTypes(["bar", "line"]);
    },
  },
  methods: {
    updateTopGrouping(event) {
      const top_grouping = event.target.value;
      this.updateSection({ ...this.section, top_grouping });
    },
    updateMetric(event) {
      const metric = event.target.value;
      this.updateSection({ ...this.section, metric, insights: null });
    },
    updateSort(event) {
      const sort = event.target.value;
      this.updateSection({ ...this.section, sort });
    },
    updateLimit(event) {
      const limit = Number.parseInt(event.target.value);
      this.updateSection({ ...this.section, limit });
    },
    updateDisplayType(event) {
      const displayType = event.target.value;
      this.updateSection({ ...this.section, displayType });
    },
    updateCumulative(event) {
      const cumulative = event.target.checked;
      this.updateSection({ ...this.section, cumulative });
    },
    updateShowDatalabels(event) {
      const showDatalabels = event.target.checked;
      this.updateSection({ ...this.section, showDatalabels });
    },
    updateBenchmarkForward(event) {
      const showDatalabels = event.target.checked;
      this.updateSection({ ...this.section, showDatalabels });
    },
    updateSelection({ type, series }) {
      this.updateSection({ ...this.section, time_period: type, time_series_type: series });
    },
    updateLimitToBusinessHours(limit_to_business_hours) {
      this.updateSection({ ...this.section, limit_to_business_hours });
    },
    updateComparison(comparison) {
      this.updateSection({ ...this.section, comparison });
    },
    updateLikeForLike(like_for_like) {
      this.updateSection({ ...this.section, like_for_like });
    },
    updateSection(section) {
      this.$emit("update-section", section);
    },
    isTimeComparison(type) {
      return _.includes(timeComparisonTypes, type);
    },
  },
  props: {
    section: {
      type: Object as PropType<any>,
    },
    newsletterType: {
      type: String,
    },
    timePeriods: {
      type: Array as PropType<MenuItem[]>,
    },
    seriesTypes: {
      type: Array as PropType<string[]>,
    },
    openingHoursDefined: {
      type: Boolean,
    },
    lflEnabled: {
      type: Boolean,
    },
    insightsVisible: {
      type: Boolean,
    },
  },
});
</script>
